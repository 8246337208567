import React from 'react';
import { SectionHeader } from '../../modules/common/components/section-header';
import { Seo } from '../../modules/common/components/seo';
import { DashboardLayout } from '../../modules/layout/components/dashboard-layout';

import './index.scss';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const HSRCharacterBuilderPage = () => {
  return (
    <DashboardLayout
      className={'generic-page hsr-character-builder'}
      game="hsr"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/star-rail/">Honkai: Star Rail</Link>
        </li>
        <li className="divider">/</li>
        <li>Character Builder</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../images/starrail/categories/category_teambuilder.png"
            alt="Team Builder"
          />
        </div>
        <div className="page-details">
          <h1>Honkai: Star Rail Character Builder </h1>
          <h2>
            A handy character builder to simulate characters and their damage in
            Honkai: Star Rail.
          </h2>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
      <div className="page-content">
        <SectionHeader title="Team DPS" />
        <p>
          The Character Builder is currently in a limbo as we're working on
          other tools for the website. Still, if you want to check how your
          characters perform in a team based setting please check Asagi's Team
          DPS Simulator. Here's the{' '}
          <a
            href="https://honkai.asagi-game.com/"
            target="_blank"
            rel="noreferrer"
          >
            link
          </a>{' '}
          and below you can see how it looks like:
        </p>
        <StaticImage
          src="../../images/starrail/generic/asagi.webp"
          alt="Team Builder"
        />
        <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
      </div>
    </DashboardLayout>
  );
};

export default HSRCharacterBuilderPage;

export const Head: React.FC = () => (
  <Seo
    title="Character Builder | Honkai: Star Rail | Prydwen Institute"
    description="A handy character builder to simulate characters and their damage in Honkai: Star Rail."
  />
);
